.circleSolid {
    border-radius: 200%;
    background-color: rgb(216, 114, 211);
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 2;
    border: 4px solid rgb(207, 65, 200)
  }

.circle {
    border-radius: 50%;
    background-color: rgb(207, 65, 200);
    width: 20px;
    height: 20px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
  }

@keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: 1;
    }
    to {
      transform: scale(2, 2);
      opacity: 0;
    }
}